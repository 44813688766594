import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./ListItem.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
const ListItem = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const refWrap = useRef(null);
  const { weekdate, realdate, name, sortId, desc } = props;
  const { dateView } = state;
  const [status, setStatus] = useState(0);
  const [currentDate, setCurrentDate] = useState([]);
  const [formatedDate, setFormatedDare] = useState([]);
  const [currentDateIndex, setCurrentDateIndex] = useState(0);


  useEffect(() => {
    if ((weekdate === undefined || weekdate === null) && realdate === undefined) return;
    const monthNames = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"];
    let dates;
    if (realdate !== undefined) {
      dates = [...realdate];
    } else {
      dates = [...weekdate];
    }
    const getDateFromWeekNumber = (year, weekNumber) => {
      const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
      const day = date.getDay();
      date.setDate(date.getDate() + (day <= 4 ? 1 - day : 8 - day));
      return date;
    }
    const postFormatDate = (date) => {
      var day = date.getDate().toString().padStart(2, '0');
      var month = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${day.replace(/^0+/, '')} ${monthNames[month - 1].toLowerCase()}`;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let status = 0;
    let dateIndex = 0;
    let formatedDate = [];
    dates.forEach((element, index) => {

      let startDate, endDate;
      if (realdate !== undefined) {
        startDate = new Date(element[0]);
        endDate = new Date(element[1]);
      } else {
        startDate = getDateFromWeekNumber(2024, element[0]);
        endDate = getDateFromWeekNumber(2024, element[1]);
        endDate.setDate(endDate.getDate() + 6);
      }
      formatedDate.push([postFormatDate(startDate), postFormatDate(endDate)]);
      if (startDate <= today && today <= endDate) {
        status = 1;
        dateIndex = index;
      }
      if (index < dates.length - 1 && today > endDate) {
        dateIndex = index + 1;
      }
    });
    setStatus(status);
    setCurrentDateIndex(dateIndex);
    setFormatedDare(formatedDate);
    // console.log('===========');
    // console.log(dateIndex);
    // console.log(formatedDate[dateIndex]);
    setCurrentDate(formatedDate[dateIndex]);


  }, [weekdate, realdate]);
  if (sortId === 1 && status === 0) return null;

  return (
    <>
      <div className={status === 1 ? `${styles.wrap} ${styles.wrap_1}` : styles.wrap} ref={refWrap}>
        <div className={styles.name}>{name}</div>
        {desc !== undefined ?
          <div className={styles.desc}>
            <span>i</span>
            <div className={styles.desc__popup}>
              {desc}
            </div>
          </div>
          : null
        }
        <div className={styles.date}>
          {currentDate !== undefined && currentDate.length > 0 ?
            currentDate.map((element, index) => {
              if(index > 0 && element === currentDate[0]) return null;
              return <span key={`data-${index}`}>
                {element}
                {index === 0 && currentDate[1] !== currentDate[0] ?
                  ' - '
                  :
                  null}
              </span>
            })
            : null}

          {formatedDate.length > 1 && currentDateIndex < formatedDate.length - 1 ?
            <div className={styles.help}>
              <span>+ даты</span>
              <div className={styles.help__popup}>
                {formatedDate !== undefined && formatedDate.length > 0 ?
                  formatedDate.map((element, index) => {
                    let dates = element.map((date, i) => {
                      return <span key={`data-${index}-${i}`}>
                        {date}
                        {i === 0 ?
                          ' - '
                          :
                          null}
                      </span>
                    });
                    if (index <= currentDateIndex) {
                      return null;
                    }
                    return <span key={`data-${index}`}>
                      {dates}
                    </span>
                  })
                  : null}
              </div>
            </div>
            : null}
        </div>

      </div>
    </>
  );

}

export default ListItem;
